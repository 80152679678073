@import '../../../theme/colors';

.d-ib {
  display: inline-block !important;
}

.reports-phase-screen {
  padding: 0 32px;
  flex: 1;
  color: $white;
  margin-left: 190px;
  height: 100% !important;
}

.scenarios-fetch {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-bottom: 70px;
}

.virtual-scroller {
  position: relative;
  // overflow: auto;
  flex: 0 0 110px;
}

.reports-phase-header {
  margin-bottom: 26px;
}

.report-phase-title {
  margin-right: 4px;
}

.scenario-name-skeleton {
  display: inline-block;
  width: 270px;
  height: 40px;
  background-color: $grey-04;
  background-image: linear-gradient(-45deg, $grey-01 20%, rgba($grey-04, 0.15) 50%, $grey-01 80%);
  background-size: 1000px 18px;
  animation: slide 1s infinite linear;
  position: absolute;
}
@keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 800px 0;
  }
}
