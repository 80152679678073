@import '../../theme/_colors';
@import '../../theme/_variables.scss';
@import '../../theme/grid/_breakpoints.scss';

.ScenarioSkeleton {
  height: 64px;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: $grey-03;
  padding: 0 24px 0 0px;
  margin-bottom: 1rem;

  &.expanded {
    height: 96px;
    border: solid 1px $grey-01;
  }
}

.Default {
  margin: 16px;
  flex: 1;
}

.Name,
.Description,
.Date,
.Time,
.Phase,
.Avatar,
.Icon {
  background-color: $grey-04;
  background-image: linear-gradient(-45deg, $grey-01 20%, rgba($grey-04, 0.15) 50%, $grey-01 80%);
  background-size: 1000px 18px;
  animation: slide 2s infinite linear;
}

.Avatar {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  margin: 0 0px;
}

.TitleSection {
  width: 120px;
  margin: 16px;

  .Name {
    height: 18px;
    margin-bottom: 4px;
    width: 80px;
    border-radius: 2px;
    animation-delay: 0.5s;
  }
  .Description {
    height: 12px;
    width: 120px;
    border-radius: 2px;
    animation-delay: 0.5s;
  }
}

.DateSection {
  width: 60px;
  margin: 16px;

  .Date {
    height: 18px;
    margin-bottom: 4px;
    width: 60px;
    border-radius: 2px;
    animation-delay: 1s;
  }
  .Time {
    height: 12px;
    width: 40px;
    border-radius: 2px;
    animation-delay: 1s;
  }
}

.ScenarioProgress {
  justify-content: flex-start;
}

.Phase {
  height: 32px;
  width: 32px;
  border-radius: 2px;
  margin: 0 8px;
  animation-delay: 1.5s;
  &.one {
    flex: 1;
  }
  &.two {
    animation-delay: 2.25s;
  }
  &.three {
    animation-delay: 2.5s;
  }
}

.Icon {
  height: 32px;
  width: 32px;
  border-radius: 24px;
  margin: 0 10px;
}

@keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.default-skeleton-phase {
  height: 48px;
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: $grey-01;
  padding: 4px;
}
