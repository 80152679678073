@import '../../theme/_variables.scss';
@import '../../theme/mixins/global';

.loading-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: progress;
    background: $body-bg;
    
    .loading-bar {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 300px;
        height: 300px;
        transform: translate(-50%, -50%);
    }
}