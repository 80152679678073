.popoveritem {
  margin-left: -0.4rem;
  padding-left: 0.6rem;
  &.unread {
    background-image: linear-gradient(to left, #5D5D62, #5D5D62);
    .scenario-unpinned {
      .itemheading-unpinned {
        color: #fff;
      }
      .itemheading-pinned {
        color: #fff;
      }
      .itemdate-pinned {
        color: #ccc;
      }
      .itemdate-unpinned {
        color: #ccc;
      }      
    }    
    .item-progress {
      color: #ccc !important;
    }
  }
}

.itemheading-pinned {
  color: white;
  margin-left: 0.3rem;
  margin-top: 12px;
}

.itemprogress {
  margin-top: -0.3rem;
  margin-left: -0.3rem;
  color: #888ea5;
  font-size: 0.75rem;
}

.itemdate-pinned {
  margin-top: 0.5rem;
  color: #4a4e61;
  font-size: 0.7rem;
  position: absolute;
  right: 2.2rem;
}

.borderseparator {
  margin-top: 0.4rem;
  margin-left: 1.5rem;
  border-bottom: 0.5px solid #2d3143;
}

.statusdot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid transparent;
  margin-left: 1.6rem;
  margin-bottom: 5px;
}

.item-progress {
  color: #888ea5 !important;
  font-size: 0.75rem !important;
  margin-top: -2.15rem !important;
  //background-color: #14161e !important;
  height: 48px;
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.statusdot.scenario-archived {
  border-color: #888ea5;
  background-color: #888ea5;
}

.statusdot.phase-started {
  border-color: #6868ff;
  background-color: #6868ff;
}

.statusdot.scenario-queued {
  border-color: #6868ff;
}

.statusdot.phase-completed {
  border-color: #2cfb7d;
}

.statusdot.scenario-completed {
  border-color: #2cfb7d;
  background-color: #2cfb7d;
}

.statusdot.scenario-duplicated {
  border-color: #ffc13f;
  background-color: #ffc13f;
}

.status-heading {
  margin-top: 0.3rem;
  margin-left: 1.5rem;
}

.item-progress.scenario-archived {
  justify-content: flex-start;
  margin-left: 2.5rem;
}

.item-progress.scenario-queued {
  justify-content: flex-start;
  margin-left: 2.5rem;
}

.item-progress.phase-started {
  justify-content: flex-start;
  margin-left: 2.5rem;
}

.item-progress.phase-completed {
  justify-content: flex-start;
  margin-left: 2.5rem;
}

.item-progress.scenario-completed {
  justify-content: flex-start;
  margin-left: 2.5rem;
}

.item-progress.scenario-duplicated {
  justify-content: flex-start;
  margin-left: 2.5rem;
}

.ScenarioNameContainer {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  &.scenario-pinned {
    justify-content: flex-start;
  }

  &.scenario-unpinned {
    justify-content: space-between;
  }
}

.itemheading-unpinned {
  color: white;
  margin-left: 0.16rem;
  margin-top: 12px;
}

.itemdate-unpinned {
  margin-top: 0.5rem;
  color: #4a4e61;
  font-size: 0.7rem;
}
