@import '../../theme/colors';

.popover-confirm {
  padding: 24px;
  background: #14161e !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: $white;

  &.top {
    top: -11rem;
  }

  .actions-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;

    .popover-action + .popover-action {
      margin-left: 10px;
    }

    &.column {
      flex-direction: column;

      .popover-action + .popover-action {
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }
}

.popover-wrapper {
  border-radius: 5px;
  .popover-container {
    // height: max-content;
    width: 15rem;
    color: white;
    margin-left: 0rem;
    text-align: center;
    background: #14161e !important;
    z-index: 999;
  }

  .popover-arrow {
    margin-left: 0rem;
    background: red !important;
  }
}
