@import '../../theme/_colors';

.validation-modal-wrapper {
  padding: 30px 40px 10px 40px;
}

.validation-modal-title {
  margin-bottom: 10px;
  color: $danger;
}

.validation-modal-subtitle {
  padding: 0px 30px;
  list-style-type: none;

  & li {
    display: flex;
    line-height: 30px;
    font-size: 16px;
    margin: 0 1rem;
    .li-header {
      font-size: 1.25rem;
    }

    .sheet {
      width: 25%;
      margin-left: 1rem;
    }

    .rows-columns {
      width: 10%;
      margin-left: 1rem;
    }

    .explain {
      width: 70%;
      margin-left: 1rem;
    }
  }
}

.validation-modal-divider {
  border: solid 1px $grey-04;
}

.custom-modal-buttons {
  position: absolute;
  display: flex;
  bottom: 10px;
  width: 100%;
  padding: 1rem;
  float: right;
  justify-content: flex-end;

  & .ok-button {
    padding: 12px 30px 12px 30px;
  }
}

.Warning {
  flex: 1;
  display: flex;
  align-items: flex-start;
  .WText {
    width: 256px;
  }
}

.form-div {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.userform-div {
  flex: 1;
}

.userform-row {
  align-items: baseline;
  input[type='text'],
  input[type='email'],
  input[type='password'],
  select {
    box-sizing: border-box;
    padding: 6px;
    border: none;
    border-bottom: 1px solid #4a4e61;
    background: transparent;
    color: #ffffff;
    width: 19rem;
  }
  input[type='checkbox'] {
    height: 20px;
    width: 20px;
    color: green;
  }
  select > option {
    background: #15161e;
  }

  label {
    margin-bottom: 0;
  }

  .validation-message {
    color: red !important;
    font-size: 0.7rem;
    font-weight: 600;
    max-width: 19rem;
  }
}

.custom-modal-dialog {
  .modal-header {
    height: auto;
    padding: 0;
  }
  .custom-modal-body {
    min-height: -moz-fit-content;
    min-height: fit-content;
    max-height: 100%;
  }
  .validation-modal-wrapper {
    padding-bottom: 0;
  }

  .userform-button {
    padding: 0;
    .custom-modal-buttons-outside-footer {
      height: auto;
      position: relative;
      bottom: auto;
    }
  }
}
