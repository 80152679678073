@import '../../theme/_colors';
@import '../../theme/_variables.scss';
@import '../../theme/grid/_breakpoints.scss';

.header {
  margin-bottom: 26px;
}

.error-notification-icon {
  position: absolute;
  left: -7px;
  top: 50%;
  transform: translateY(-50%);
}

.scenario-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .scenario {
    height: 64px;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    background-color: $grey-03;
    padding: 0 16px 0 0px;
    margin-left: 7px;
    margin-bottom: 16px;

    &.running {
      height: 96px;
      border: solid 1px $primary;
    }

    .title-section {
      width: 120px;
      margin: 16px;

      .title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.43;
        font-family: $font-family-henderson;
      }

      .special-link {
        color: $white;

        &:hover {
          color: $primary-light;
        }
      }
    }

    .date-section {
      width: 60px;
      margin: 16px;
    }
  }

  .default-scenario-progress {
    margin: 0 16px;
    flex: 1;
  }

  .empty-box {
    padding: 23px 0;
    display: inline-flex;
    justify-content: center;
    background-color: transparent;
    border-radius: 4px;
    border: 1px dashed #4a4e61;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: $grey-06;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
