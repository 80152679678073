@import '../../../../../../theme/colors';
@import '../../../../../../theme/variables';

.dapi-input {
  width: 188px;
  margin-bottom: 32px;
  margin-right: 42px;
}

.dapi-body {
  margin: 48px 0 32px 48px;

  .input-label {
    color: $form-color;
    cursor: pointer;
    font-weight: $form-font-weight;
    font-size: 12px;
    margin-bottom: 0;
    transition: $transition;
    &.hover {
      color: $white;
    }
    &.checked {
      color: $white;
    }
    &.disabled {
      color: $grey-05;
      cursor: default;
    }
    &.error {
      color: $danger;
    }
  }
}

.date-input {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 64px;

  input {
    width: 100%;
    min-height: 28px;
    border: none;
    border-bottom: 1px solid #4a4e61;
    background: transparent;
    color: #ffffff;
    font-family: 'Henderson', 'Helvetica Neue', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    font-weight: 400;
    transition: border-color 0.2s ease-in-out;
    padding-bottom: 5px;

    &:focus {
      outline: none;
    }
  }

  &[readonly] {
    cursor: default;
    border-color: transparent;
    color: $white;
  }
  &[disabled] {
    cursor: not-allowed;
    border-color: transparent;
    color: $white;
  }

  &.warning {
    .input-label {
      color: $warning;
    }
    input {
      border-bottom-color: rgba($warning, 0.6);
    }
    &.focused,
    &:hover {
      .Units {
        color: $grey-07;
      }
    }
  }
  &.error {
    .input-label {
      color: $danger;
    }
    input {
      border-bottom-color: rgba($danger, 0.6);
    }
    &.focused,
    &:hover {
      .Units {
        color: $grey-07;
      }
    }
  }

  // Hover for warning and error
  &:hover {
    &.warning {
      input {
        border-bottom-color: rgba($warning, 1);
      }
    }
    &.error {
      input {
        border-bottom-color: rgba($danger, 1);
      }
    }
  }

  &.center.readonly {
    input {
      text-align: center;
    }
    .Units {
      left: 72px;
    }
  }

  // On focus
  &.focused:not(.readonly):not(.error):not(.warning) {
    .input-label,
    .Units {
      color: $primary-ultra-light;
    }
    border-bottom-color: $primary;
  }

  // Hover normal
  &:hover:not(.error):not(.warning):not(.focused):not(.readonly):not(.disabled) {
    .input-label,
    .Units {
      color: $grey-07;
    }
    input {
      border-bottom-color: $grey-07;
    }
  }
}

.message {
  margin-top: 4px;
  color: #aeb2c1 !important;
}

.date-select {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 64px;

  .date-input-label {
    color: $grey-06;
    transition: color 0.5s ease-in-out;
    height: 17px;
    font-size: 12px;
    font-family: $base-font-family;
    font-weight: $font-weight-normal;
    line-height: 1.5;

    &.hover {
      color: $white;
    }
    &.focused {
      color: $primary-ultra-light;
    }
    &.warning {
      color: $warning;
    }
    &.error {
      color: $danger;
    }
    &.readonly {
      color: $grey-06;
    }
  }

  .date-input-select {
    width: 100%;
    min-height: 28px;
    border: none;
    border-bottom: 1px solid #4a4e61;
    background: transparent;
    color: #ffffff;
    font-family: 'Henderson', 'Helvetica Neue', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    font-weight: 400;
    transition: border-color 0.2s ease-in-out;
    padding-bottom: 5px;

    &:hover {
      outline: none;
    }
  }

  .date-input-select-option {
    background: #12141c;
    height: 28px;
    color: #ffffff;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
  }
}
