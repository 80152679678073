@import '../../theme/_colors';
@import '../../theme/_variables.scss';

.WLink {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  color: $white;
  font-size: $font-size;
  font-family: $base-font-family;
  font-weight: $font-weight-normal;
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  .WIcon {
    &:before {
      border: 1px solid $grey-05;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      transition: all 0.3s ease;
    }
  }
  &.right {
    .WIcon {
      margin-left: 8px;
    }
  }
  &.left {
    flex-direction: row-reverse;
    .WIcon {
      margin-right: 8px;
    }
  }
  &[disabled] {
    color: $grey-05;
    cursor: default;
    .WIcon {
      color: $grey-05;
      &:before {
        border-color: $grey-03;
      }
    }
  }
  &:hover:not([disabled]) {
    .WIcon {
      &:before {
        border-color: $white;
      }
    }
  }
}

.Back {
  margin-bottom: 20px;
}
