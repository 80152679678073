@import '../../theme/_colors';
@import '../../theme/_variables.scss';
@import '../../theme/grid/_breakpoints.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.WText {
  color: $white;
  margin-bottom: 0;
  font-family: $font-family-henderson;

  & > a {
    height: 12px;
    color: $primary;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-text-decoration-skip: objects;

    &:hover {
      color: $primary-light;
    }
  }

  // Fonts: try to use this fonts already defined
  &.h1 {
    font-family: $headings-font-family;
    font-size: 32px;
    line-height: 38px;
  }
  &.h2 {
    font-family: $headings-font-family;
    font-size: 28px;
    line-height: 34px;
  }
  &.h3 {
    font-family: $headings-font-family;
    font-size: 24px;
    line-height: 30px;
  }
  &.h4 {
    font-family: $headings-font-family;
    font-size: 20px;
    line-height: 26px;
  }
  &.h5 {
    font-family: $headings-font-family;
    font-size: 18px;
    line-height: 24px;
  }
  &.h6 {
    font-family: $headings-font-family;
    font-size: 16px;
    line-height: 22px;
  }
  &.p,
  &.body {
    font-family: $base-font-family;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 1.43;
  }
  &.title {
    font-size: 18px;
    line-height: 24px;
    font-weight: $font-weight-light;
  }
  &.bodyLight {
    font-family: $base-font-family;
    font-weight: $font-weight-light;
    font-size: 14px;
    line-height: 1.43;
  }
  &.small {
    font-family: $base-font-family;
    font-weight: $font-weight-normal;
    font-size: 12px;
    line-height: 1.5;
  }
  &.smallLight {
    font-family: $base-font-family;
    font-weight: $font-weight-light;
    font-size: 12px;
    line-height: 1.5;
  }
  &.caption {
    font-family: $base-font-family;
    font-weight: $font-weight-normal;
    font-size: 10px;
    line-height: 1.2;
  }
  &.captionLight {
    font-family: $base-font-family;
    font-weight: $font-weight-light;
    font-size: 10px;
    line-height: 1.2;
  }
  &.overline {
    font-family: $base-font-family;
    font-weight: $font-weight-normal;
    font-size: 9px;
    line-height: 1.11;
    letter-spacing: 1px;
  }

  // Force size
  &.xs {
    font-size: 12px;
  }
  &.sm {
    font-size: 14px;
  }
  &.md {
    font-size: 18px;
  }

  // Colors
  &.white {
    color: $white;
  }
  &.black,
  &.dark {
    color: $black;
  }
  &.primary {
    color: $primary;
  }
  &.primaryDark {
    color: $primary-dark;
  }
  &.secondary {
    color: $primary-light;
  }
  &.success {
    color: $success;
  }
  &.warning {
    color: $warning;
  }
  &.danger,
  &.red {
    color: $danger;
  }
  &.grayDark,
  &.grey01 {
    color: $grey-01;
  }
  &.grey05 {
    color: $grey-05;
  }
  &.grey06 {
    color: $grey-06;
  }
  &.gray,
  &.grey07 {
    color: $grey-07;
  }
  &.ultralight {
    color: $primary-ultra-light;
  }

  // Force weight
  &.thin {
    font-weight: $font-weight-thin;
  }
  &.light {
    font-weight: $font-weight-light;
  }
  &.normal {
    font-weight: $font-weight-normal;
  }
  &.bold {
    font-weight: $font-weight-bold;
  }

  // Align
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  // Other styles
  &.uppercase {
    text-transform: uppercase;
  }
  &.italic {
    font-style: italic;
  }
  &.underline {
    text-decoration: underline;
  }
  &.capitalize {
    text-transform: capitalize;
  }
}
