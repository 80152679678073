@import '../../theme/colors';

.button-icon {
  border: none;
  background: none;
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease;
  margin: 0 -6px;
  margin-top: 14.805px;
  &:active {
    opacity: 0.4;
  }
  &:focus {
    outline-style: none;
  }
  &.disabled {
    opacity: 1;
    background: none;
    cursor: not-allowed;
    .WText {
      color: $grey-05;
    }
    .WIcon {
      color: $grey-05;
    }
  }
  &.small {
    height: 48px;
    width: 48px;
    margin: 8px;
  }
  &.large {
    height: 75px;
    width: 75px;
  }
}
