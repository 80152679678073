@import '../../theme/_colors';
@import '../../theme/variables';

$icon-size: 16px;

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: $spacer;
}

.checkbox-icon {
  cursor: pointer;
  position: relative;
  touch-action: manipulation;
  width: $icon-size;
  height: $icon-size;
  display: inline-block;
  &::before {
    content: '';
    border: 1px solid $grey-04;
    background-color: $grey-03;
    width: $icon-size;
    height: $icon-size;
    display: block;
    box-sizing: border-box;
    border-radius: $border-radius;
    position: absolute;
    left: 0;
    top: 0;
    transition: $transition;
    z-index: 1;
  }
  & .WIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  &.hover {
    &::before {
      border-color: $grey-05;
    }
  }

  &.checked {
    &::before {
      border-color: $primary;
      background-color: $primary;
    }
    &.hover {
      &::before {
        border-color: $primary-light;
        background-color: $primary-light;
      }
    }
  }
  &.disabled {
    &::before {
      border-color: $grey-04;
      background-color: transparent;
    }
    &.checked {
      &::before {
        border-color: $grey-04;
        background-color: transparent;
      }
    }
  }
  &.error,
  &.error.hover {
    &::before {
      border-color: $danger;
      background-color: transparent;
    }
  }
}

.checkbox-input {
  display: none;
}

.checkbox-label {
  color: $form-color;
  cursor: pointer;
  font-weight: $form-font-weight;
  font-size: $form-font-size;
  margin-left: $spacer;
  margin-bottom: 0;
  transition: $transition;
  &.hover {
    color: $white;
  }
  &.checked {
    color: $white;
  }
  &.disabled {
    color: $grey-05;
    cursor: default;
  }
  &.error {
    color: $danger;
  }
}
