@import '../../theme/_colors';
@import '../../theme/_variables.scss';

.main-content {
  display: flex;
  padding: 10px 0;
  margin-top: 64px;
  min-height: calc(100vh - 64px);
  background-color: $grey-01;
}

.divider {
  margin-left: 30px;
  margin-right: 30px;
  border-color: $black-o-40;
}
