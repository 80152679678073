@import '../../../theme/colors';
@import '../../../theme/variables';

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-body {
  margin: 48px 0 32px 48px;

  .input-label {
    color: $form-color;
    cursor: pointer;
    font-weight: $form-font-weight;
    font-size: 12px;
    margin-bottom: 0;
    transition: $transition;

    &.hover {
      color: $white;
    }

    &.checked {
      color: $white;
    }

    &.disabled {
      color: $grey-05;
      cursor: default;
    }

    &.error {
      color: $danger;
    }
  }
}

.login-input {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 64px;
  width: 300px;
  margin-bottom: 32px;
  margin-right: 48px;

  input {
    width: 100%;
    min-height: 28px;
    border: none;
    border-bottom: 1px solid #4a4e61;
    background: transparent;
    color: #ffffff;
    font-family: 'Henderson', 'Helvetica Neue', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    font-weight: 400;
    transition: border-color 0.2s ease-in-out;
    padding-bottom: 5px;

    &:focus {
      outline: none;
    }
  }

  &.warning {
    .input-label {
      color: $warning;
    }

    input {
      border-bottom-color: rgba($warning, 0.6);
    }

    &.focused,
    &:hover {
      .Units {
        color: $grey-07;
      }
    }
  }

  &.error {
    .input-label {
      color: $danger;
    }

    input {
      border-bottom-color: rgba($danger, 0.6);
    }

    &.focused,
    &:hover {
      .Units {
        color: $grey-07;
      }
    }
  }

  // Hover for warning and error
  &:hover {
    &.warning {
      input {
        border-bottom-color: rgba($warning, 1);
      }
    }

    &.error {
      input {
        border-bottom-color: rgba($danger, 1);
      }
    }
  }

  &.center.readonly {
    input {
      text-align: center;
    }

    .Units {
      left: 72px;
    }
  }

  // On focus
  &.focused:not(.readonly):not(.error):not(.warning) {
    .input-label,
    .Units {
      color: $primary-ultra-light;
    }

    border-bottom-color: $primary;
  }

  // Hover normal
  &:hover:not(.error):not(.warning):not(.focused):not(.readonly):not(.disabled) {
    .input-label,
    .Units {
      color: $grey-07;
    }

    input {
      border-bottom-color: $grey-07;
    }
  }
}

.phase2-input {

}

.message {
  margin-top: 4px;
  color: #aeb2c1 !important;
}

.Logo-login-page {
  display: flex;
  justify-content: center;

  img {
    height: auto;
    width: 200px;
    position: relative;
    top: 2px;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.6;
    }
  }
}
.login-btn{
  width: 50%;
  height: 3rem;
}
.login-btn-container{
  display: flex;
  justify-content: center;
}

