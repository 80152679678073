.svg-container {
    position: relative;
    width: 24px; /* Adjust as necessary */
    height: 24px; /* Adjust as necessary */
  }
  
  .circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .chevron {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px; /* Adjust as necessary */
    height: 12px; /* Adjust as necessary */
    transform: translate(-50%, -50%);
    fill: white; /* Adjust color if necessary */
  }