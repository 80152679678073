@import '../../theme/_variables.scss';

.view-more-divider {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: $grey-05;
}

.view-more-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.view-more-wrapper-text {
  display: flex;
  justify-content: center;
  margin: 0 8px;
  min-width: 80px;
  & .WText {
    margin-right: 8px;
    white-space: nowrap;
  }
}
