@import '../../theme/_colors';

.validation-modal-wrapper {
  padding: 30px 40px 10px 40px;
}

.validation-modal-title {
  margin-bottom: 10px;
  color: $danger;
}

.validation-modal-subtitle {
  padding: 10px 30px;
  list-style-type: none;
  overflow: auto;
  height: 100%;

  .li-error {
    font-size: 0.75rem;
  }

  li:first-child {
    font-size: 0.9rem;
  }

  & li {
    display: flex;
    line-height: 30px;
    margin: 0 1rem;
    .li-header {
      font-size: 0.9rem;
    }

    .sheet {
      width: 25%;
      margin-left: 1rem;
    }

    .rows-columns {
      width: 10%;
      margin-left: 1rem;
    }

    .explain {
      width: 70%;
      margin-left: 1rem;
    }
  }
}

.validation-modal-divider {
  border: solid 1px $grey-04;
}

.validation-modal-buttons {
  position: absolute;
  bottom: 10px;
  right: 25px;

  & a {
    padding: 12px 30px 12px 30px;
    text-decoration: none;
  }

  & .ok-button {
    padding: 12px 30px 12px 30px;
  }
}

.Warning {
  flex: 1;
  display: flex;
  align-items: flex-start;
  .WText {
    width: 256px;
  }
}
