.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.border-box {
  box-sizing: border-box !important;
  overflow-x: hidden;
}

.row-custom {
  padding: 10px;
  width: 100%;
}

/* Create four equal columns that floats next to each other */
.column {
  float: left;
  width: 29%;
  padding: 20px;
  background: rgb(30, 34, 46);
  border-radius: 20px;
  margin: 10px;
  height: 300px;
  overflow-y: auto;
}

.title-custom {
  margin-bottom: 10px;
  padding-bottom: 15px;
  font-size: 18px;
  border-top: thin green;
  border-right: thin green;
  border-bottom: thin solid green;
  border-left: thin green;
  font-weight: 100;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 900px) {
  .column {
    width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
