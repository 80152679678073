@import '../../theme/_colors';

.wrapper {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: $grey-04;
  display: flex;
  justify-content: center;
  align-items: center;

  &.clickable {
    &:hover {
      background-color: $grey-05;
    }
  }

  &.large {
    height: 80px;
    width: 80px;
    border-radius: 40px;
  }

  &.medium {
    height: 40px;
    width: 40px;
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  .custom-hover-content {
    position: absolute;
    display: none;
  }

  &:hover {
    .custom-hover-content {
      display: block;
    }
  }

  .custom-content {
    position: absolute;
  }
}

.WAvatar {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.large {
    height: 70px;
    width: 70px;
    border-radius: 35px;

    .WText {
      font-size: 15px;
    }
  }

  &.medium {
    height: 32px;
    width: 32px;

    .WText {
      font-size: 10px;
    }
  }

  &.small {
    height: 20px;
    width: 20px;

    .WText {
      font-size: 8px;
    }
  }

  &.custom-content:hover {
    opacity: 0.2;
    cursor: pointer;
  }
}

img {
  height: 40px;
  width: 40px;
  border-radius: 20px;

  &.large {
    height: 70px;
    width: 70px;
    border-radius: 35px;
  }

  &.medium {
    height: 32px;
    width: 32px;
  }

  &.small {
    height: 20px;
    width: 20px;
  }

  &.custom-content:hover {
    opacity: 0.2;
    cursor: pointer;
  }
}

