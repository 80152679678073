@import '../../theme/_variables.scss';

.subphase-header {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  background: linear-gradient(90deg, rgba($grey-03, 1), rgba($grey-03, 0));
  border-radius: 4px;
  width: 100%;

  :first-child {
    margin-right: 8px;
  }
}

.texts {
  display: flex;
  align-items: baseline;
}
