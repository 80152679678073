@import '../../../../../theme/colors';
@import '../../../../../theme/variables';

.phase2-input {
  width: 188px;
  margin-bottom: 32px;
  margin-right: 48px;
}
.phase2-body {
  margin: 48px 0 32px 48px;

  .input-label {
    color: $form-color;
    cursor: pointer;
    font-weight: $form-font-weight;
    font-size: 12px;
    margin-bottom: 0;
    transition: $transition;
    &.hover {
      color: $white;
    }
    &.checked {
      color: $white;
    }
    &.disabled {
      color: $grey-05;
      cursor: default;
    }
    &.error {
      color: $danger;
    }
  }
}
