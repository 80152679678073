@import '../../theme/_variables.scss';

.phase-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  :global(.WText) {
    font-weight: $font-weight-normal;
  }
}
