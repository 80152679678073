@import '../../../../theme/colors';

// span {
//   width: 32px;
//   height: 18px;
//   font-size: 12px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: center;
//   color: $black;
// }

.ScenarioProgressPhase {
  position: relative;
  height: 32px;
  width: 32px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $success;
  margin: 4px;

  &.warning {
    background-color: $warning;
  }

  &.disabled {
    background-color: $grey-03;
  }
}

.Notification {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid lighten($black, 8%);
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: $warning;
}
