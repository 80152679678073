@import '../../theme/_colors';
@import '../../theme/variables';

.duplicate-wrapper {
  padding: 57px 72px 72px 72px;
}

.duplicate-title {
  margin-bottom: 10px;
}

.duplicate-subtitle {
  margin-bottom: 28px;
}

.duplicate-divider {
  border: solid 1px $grey-04;
}

.duplicate-phases {
  display: flex;
  margin-top: 28px;
}

.duplicate-phase {
  width: 256px;
  &:not(:last-child) {
    margin-right: 32px;
  }
}

.duplicate-phase-title {
  margin-bottom: 4px;
}

.duplicate-phase-subtitle {
  margin-left: 32px;
}

.duplicate-phase-subphases {
  width: 256px;
  border: solid 1px $grey-05;
  border-radius: 4px;
  padding: 12px 16px 0 16px;
  margin: 12px 0;
}

.duplicate-phase-subphase {
  margin-bottom: 12px;
}

.duplicate-buttons {
  display: flex;
  margin-top: 48px;
  justify-content: flex-end;
  & *:not(:first-child) {
    margin-left: 16px;
  }
}

.duplicate-warning {
  flex: 1;
  display: flex;
  align-items: flex-start;
  .WText {
    width: 256px;
  }
}

.duplicate-loading {
  position: absolute;
  float: left;
  top: -12%;
  left: -5%;
  // transform: translate(-50%, -50%);
}
