@import '../../theme/_colors.scss';
@import '../../theme/_variables.scss';

.footer-container {
  display: flex;
  align-items: center;
  justify-content: right;
  position: fixed;
  // left: 0;
  bottom: 0;
  right: 0.5rem;
  width: 100%;
  height: 20px; /* Height of the footer */
  background: transparent; // $grey-01;
  font-family: $base-font-family;
  color: $grey-06;
  font-size: 0.6rem;  
  z-index: 1001;
}
