@import '../../theme/_colors';
@import '../../theme/_variables.scss';
@import '../../theme/grid/_breakpoints.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.custom-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: 9996;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9997;
  background-color: rgba($black, 0.8);
}

.custom-modal-dialog {
  max-width: 976px;
  // width: 30%;
  height: auto;
  margin: 50px auto;
  position: relative;
  border-radius: $border-radius * 2;
  border: 1px solid rgba($white, 0.2);
  background: $grey-02;
  z-index: 9998;
  @include media-breakpoint-down(md) {
    margin: 0px auto;
  }
}

.modal-header {
  height: 15%;
}

.custom-modal-body {
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 60vh;
  height: 60vh;
}

.modal-footer {
  height: 100px;
}

.modal-close-btn {
  cursor: pointer;
  font-size: 16px;
  color: $white;
  position: absolute;
  top: 30px;
  right: 30px;
}

.modal-enter {
  opacity: 0;
}

.modal-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.modal-exit-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.modal-exit {
  opacity: 0;
}
