@import '../../theme/colors';

.workbook-navbar {
  overflow: hidden;
  background-color: #1e222e;
}

.workbook-navbar a {
  float: left;
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.workbook-subnav {
  float: left;
  overflow: hidden;
  &-skeleton {
    display: flex;
    & .subnavbtn-skeleton {
      width: 25%;
      height: 64.67px;
      background-color: $grey-04;
      background-image: linear-gradient(-45deg, $grey-01 20%, rgba($grey-04, 0.15) 50%, $grey-01 80%);
      background-size: 1000px 18px;
      animation: slide 1s infinite linear;
    }
  }
  &.activated {
    color: $white;
    background-color: green;
  }
  // &:hover {
  //   background-color: $green-hover;
  // }
}

@keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.workbook-subnav .workbook-subnavbtn {
  font-size: 13px;
  border: none;
  outline: none;
  color: #c8c7ce;
  padding: 25px 45px;
  font-family: inherit;
  margin: 0;
  background-color: inherit;
}

.workbook-subnav-content {
  display: block;
  position: absolute;
  left: 0;
  background-color: $grey-04;
  width: 100%;

  a.activated {
    background-color: $green-hover !important;
    color: $white;
  }
}
