@import '../../theme/_colors';
@import '../../theme/_variables.scss';
@import '../../theme/grid/_breakpoints.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.reset-session-modal-wrapper {
  padding: 30px 40px 10px 40px;
}

.reset-session-modal-title {
  margin-bottom: 10px;
  color: $danger;
  display: flex;
}

.reset-session-modal-subtitle {
  padding: 0px 30px;
  list-style-type: none;

  & li {
    display: flex;
    line-height: 30px;
    font-size: 16px;
    margin: 0 1rem;

    .li-header {
      font-size: 1.25rem;
    }

    .sheet {
      width: 25%;
      margin-left: 1rem;
    }

    .rows-columns {
      width: 10%;
      margin-left: 1rem;
    }

    .explain {
      width: 70%;
      margin-left: 1rem;
    }
  }
}

.reset-session-modal-divider {
  border: solid 1px $grey-04;
}

.reset-session-modal-buttons {
  bottom: 10px;
  right: 25px;

  & a {
    padding: 12px 30px 12px 30px;
    text-decoration: none;
  }

  & .ok-button {
    padding: 12px 30px 12px 30px;
  }
}

.Warning {
  flex: 1;
  display: flex;
  align-items: flex-start;

  .WText {
    width: 256px;
  }
}

.session-modal {
  display: flex;
  justify-content: center;
}

.session-modal-dialog {
  position: fixed;
  top: 0;
  max-width: 976px;
  height: auto;
  width: 30%;
  margin: 25px auto;
  border-radius: $border-radius * 2;
  border: 1px solid rgba($white, 0.2);
  background: $grey-02;
  z-index: 9998;
  @include media-breakpoint-down(md) {
    margin: 0px auto;
  }
}

.session-modal-header {
  padding: 0 !important;
  border-bottom: none!important;
}

.session-modal-footer {
  height: 4rem !important;
  display: flex;
  justify-content: center;
}