@import '../../theme/mixins/global';
@import '../../theme/_variables.scss';

.scenario-screen {
  flex: 1;
  position: relative;
  color: $white;
  padding: 0 32px;
  margin-left: 190px;
  .page-spinner {
    display: flex;
    justify-content: center;
  }
}

.scenario-title {
  display: flex;
  align-items: center;
  margin: 0px -16px 8px -8px;
}

.name-input {
  width: 600px;
  margin-bottom: 8px;
  margin-right: 48px;
}

.scenario-config-header {
  display: flex;
  margin: 4px 0 24px 0;
  align-items: center;
}

.ml-2 {
  margin-left: 32px !important;
}

.ml-1 {
  margin-left: 16px !important;
}

.user-input-select {
  width: 100%;
  min-height: 28px;
  border: none;
  border-bottom: 1px solid #4a4e61;
  background: transparent;
  color: #ffffff;
  font-family: 'Henderson', 'Helvetica Neue', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  font-weight: 400;
  transition: border-color 0.2s ease-in-out;
  padding-bottom: 5px;

  &:hover {
    outline: none;
  }
}

.user-input-select-option {
  background: #12141c;
  height: 28px;
  color: #ffffff;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
}

.scenarion-submit-button-hide {
  position: absolute;
  top: -100000rem;
  left: -100000rem;
  display: none;
}

.scenario-disable-cursor {
  cursor: not-allowed;
}

.scenario-enable-cursor {
  cursor: pointer;
}

.align-item-horizontal-center {
  display: flex;
  align-items: center;
  justify-content: center;
}