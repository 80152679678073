$primary: #4e4ee0;
$primary-dark: #3e3eb3;
$primary-light: #6868ff;
$primary-ultra-light: #9eb5ff;
$success: #2cfb7d;
$warning: #ffc13f;
$danger: #ff5149;
$danger-dark: darken(#ff5149, 10%);
$danger-light: lighten(#ff5149, 10%);
$black: #0e0e13;
$white: #ffffff;
$info: $primary;
$black-o-40: rgba($black, 0.4);
$grey-01: #12141c;
$grey-02: #14161e;
$grey-03: #1e222e;
$grey-04: #2d3143;
$grey-05: #4a4e61;
$grey-06: #888ea5;
$grey-07: #aeb2c1;
$green: green;
$green-hover: #2d942d;
