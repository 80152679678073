.table-container {
    max-width: 1500px;
    height: 600px;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: scroll;

  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px;
    // border: 1px solid #333;
  padding: 10px 15px;
  text-align: left;
  white-space: pre-line;
  }

  thead {
    position: sticky;
    top: 0;
    background-color: #12141c; /* Match your header background color */
    z-index: 1;
  }

  .custom-tooltip td {
    padding: 0px;
    height: auto !important;
    color: #000000 !important;
  }

  .custom-tooltip table {
     border-collapse: 'collapse';
     font-size: '12px';
  }
