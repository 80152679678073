@import '../../../../../../theme/colors';
@import '../../../../../../theme/variables';

.mb-100 {
  margin-bottom: 100px !important;
}

.opi-input {
  width: 30%;
  margin-bottom: 32px;
  margin-right: 32px;
}

.opi-body {
  margin: 48px 0 32px 48px;

  .input-label {
    color: $form-color;
    cursor: pointer;
    font-weight: $form-font-weight;
    font-size: 12px;
    margin-bottom: 0;
    transition: $transition;
    &.hover {
      color: $white;
    }
    &.checked {
      color: $white;
    }
    &.disabled {
      color: $grey-05;
      cursor: default;
    }
    &.error {
      color: $danger;
    }
  }

  .opi-input-select {
    width: 100%;
    min-height: 28px;
    border: none;
    border-bottom: 1px solid #4a4e61;
    background: transparent;
    color: #ffffff;
    font-family: 'Henderson', 'Helvetica Neue', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    font-weight: 400;
    transition: border-color 0.2s ease-in-out;
    padding-bottom: 5px;

    &:hover {
      outline: none;
    }
  }

  .opi-input-select-option {
    background: #12141c;
    height: 28px;
    color: #ffffff;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    &:hover {
      background: $primary;
    }
  }
}
