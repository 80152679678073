@import '../../theme/colors';

.workspace {
  padding: 0 32px;
  flex: 1;
  color: $white;
  margin-left: 190px;

  & > *:not(:last-child) {
    margin-bottom: 70px;
  }
}
