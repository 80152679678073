body {
  margin: 0;
  padding: 0;
  font-family: 'Henderson', 'Helvetica Neue', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #12141c;
  font-size: 14px;
  line-height: 1;
  text-align: left;
}

.mt-2 {
  margin-top: 32px !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #197A56; /* #3EAD92 */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #29BA74; 
}