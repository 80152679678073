@import '../../theme/colors';

.sub-phase {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: $grey-03;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: border 0.3s ease;

  &.void {
    border: dashed 1px $primary;
    background-color: transparent;
  }

  &.warning,
  &.valid {
    border: solid 1px $primary;
    background-color: transparent;
  }

  &.error {
    border: dashed 1px $danger;
    background-color: transparent;
  }

  &.done {
    border: solid 1px rgba($success, 0.2);
    background-color: transparent;
    &.expired {
      border: solid 1px rgba($warning, 0.2);
    }
  }

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.subphase-notification {
  width: 6px;
  height: 6px;
  border: solid 1px $primary;
  border-radius: 3px;
  position: absolute;
  top: -3px;
  right: -3px;
  background-color: $grey-01;
  transition: all 0.3s ease;

  &.valid {
    border: solid 1px $success;
    background-color: $success;
  }

  &.warning {
    border: solid 1px $warning;
    background-color: $warning;
  }

  &.error {
    border: solid 1px $danger;
    background-color: $danger;
  }
}
