@import '../../theme/colors';

.report-pop-out-detail-screen {
  position: relative;
  flex: 1;
  color: $white;
  height: 100% !important;
  z-index: 11000;
  margin: 1rem;
}

.report-pop-out-flex {
  display: flex;
  justify-content: space-between;
}
