.tooltip-avator{
    .tooltip-container{
        height: auto;
        width: auto;
        color: white;
        margin-left: 0rem;
        text-align: center;
        // border-color: #4a4e61;
        margin-left: -1rem;
        
    }

    .tooltip-arrow {
        margin-left: 1rem;
        background-color: #14161e;
      }

    .tooltip-name{
        margin-top: 0.5rem;
        text-align: left;
        margin-left: 0.55rem;
    }

    .tooltip-email{
        color: #4a4e61;
        text-align: left;
        margin-left: 0.5rem;
        margin-top: 0.5rem;
    }

    .logoutbtn{
        text-align: left;
        background-color: #14161e;
        margin-left: -0.4rem;
        margin-top: 0.1rem;
    }

}
