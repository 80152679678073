.upload-excel-body {
  margin: 48px 64px 64px 48px;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.section-body {
  margin: 48px 64px 64px 48px;
  border-radius: 4px;
  border: 1px dashed #4a4e61;
  transition: border-color 0.4s;
  width: 90%;
  padding: 20px;

  .upload-area {
    text-align: center;
    text-align: -webkit-center;
  }
}

.download-view-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.info-wrapper {
  width: 100%;
  margin-top: 20px;
  height: 50px;
  position: relative;

  & .info-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .download-content {
      margin-top: 10px;
      z-index: 1000;
    }
  }
}

.delete-upload-file-button {
  display: flex;
  align-content: center;
  justify-content: flex-end;

  :first-child {
    position: absolute; 
    height: inherit;
    width: inherit;
    right: 2.2rem;
  }
}