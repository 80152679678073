@import '../../theme/colors';
@import '../../theme/variables';

$button-border-radius: 24px;

.scenario-create {
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px;
  z-index: 1001;
}

.dialog-scenario {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1003;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  background-color: rgba($black, 0.96);
}

.close-btn {
  background: transparent;
  border: 1px solid $grey-05;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  height: 48px;
  width: 48px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s ease;
  &:hover,
  &:focus,
  &:active {
    border-color: $white;
    outline: none;
  }
}

.content-create {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);

  & > .WInput {
    max-width: 700px;
  }
  &,
  .create-button {
    margin-top: 80px;
  }
}

.open-close-enter {
  opacity: 0;
}

.open-close-enter-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.open-close-exit {
  opacity: 1;
}

.open-close-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}

.WInput {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 64px;
  align-items: center;

  // Size
  &.bigger {
    height: 180px;
    input {
      font-size: 64px;
      font-weight: 100;
      padding-bottom: 20px;
      // @include placeholder {
      //   color: $grey-04;
      // }
    }
    .Message {
      display: flex;
      align-items: center;
      padding-top: 16px;
      font-size: 14px;
      .WIcon {
        margin-right: 8px;
      }
    }
  }
  &.warning {
    .Label {
      color: $warning;
    }
    input {
      border-bottom-color: rgba($warning, 0.6);
    }
    &.focused,
    &:hover {
      .Units {
        color: $grey-07;
      }
    }
  }
  &.error {
    .Label {
      color: $danger;
    }
    input {
      border-bottom-color: rgba($danger, 0.6);
    }
    &.focused,
    &:hover {
      .Units {
        color: $grey-07;
      }
    }
  }

  // Hover for warning and error
  &:hover {
    &.warning {
      input {
        border-bottom-color: rgba($warning, 1);
      }
    }
    &.error {
      input {
        border-bottom-color: rgba($danger, 1);
      }
    }
  }

  &.center.readonly {
    input {
      text-align: center;
    }
    .Units {
      left: 72px;
    }
  }

  // On focus
  &.focused:not(.readonly):not(.error):not(.warning) {
    .Label,
    .Units {
      color: $primary-ultra-light;
    }
    input {
      border-bottom-color: $primary;
    }
  }

  // Hover normal
  &:hover:not(.error):not(.warning):not(.focused):not(.readonly):not(.disabled) {
    .Label,
    .Units {
      color: $grey-07;
    }
    input {
      border-bottom-color: $grey-07;
    }
  }
}

.formik-input {
  width: 100%;
  min-height: 60px;
  border: none;
  border-bottom: $form-border;
  background: transparent;
  color: $white;
  font-family: $base-font-family;
  font-size: $form-font-size;
  font-weight: $font-weight-light;
  transition: border-color 0.2s ease-in-out;
  padding-bottom: 5px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px #12141c inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
  }

  &:focus {
    outline: none;
  }

  /* Change Autocomplete styles in Chrome*/
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px #12141c inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
  }

  &[readonly] {
    cursor: default;
    border-color: transparent;
    color: $white;
  }
  &[disabled] {
    cursor: not-allowed;
    border-color: transparent;
    color: $white;
  }

  // Padding
  &.sm {
    padding-right: 20px;
  }
  &.md {
    padding-right: 35px;
  }
  &.lg {
    padding-right: 55px;
  }
}

.error-pop-enter {
  opacity: 0;
}

.error-pop-enter-active {
  opacity: 1;
  transition: opacity 1000ms out-in;
}

.error-pop-exit {
  opacity: 1;
}

.error-pop-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.error-message {
  display: flex;
  margin-top: 6px;
  color: $grey-07 !important;
  align-items: center;

  .text-message {
    margin-left: 0.5rem;
  }
}

.btn {
  background: rgba($primary, 0.2);
  border: none;
  border-radius: $button-border-radius;
  color: $white;
  cursor: pointer;
  outline: none;
  font-size: $font-size;
  font-family: $base-font-family;
  font-weight: $font-weight-normal;
  line-height: 1.43;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 146px;
  height: 48px;
  &.disabled {
    cursor: default;
  }

  &.block {
    width: 100%;
  }

  &.loading {
    padding: 0;
    opacity: 1;
    width: 50px;
    height: 50px;
  }
  // Types
  &.primary:not(.animated) {
    background-color: $primary;
    &:hover {
      background-color: $primary-light;
    }
    &:active,
    &.active {
      background-color: $primary-dark;
    }
    &.disabled {
      opacity: 0.2;
      background-color: $primary-dark;
    }
  }
  &.secondary {
    border: 1px solid $grey-05;
    background-color: $grey-01;
    &:hover {
      border-color: $primary-light;
    }
    &:active,
    &.active {
      border-color: $primary-dark;
    }
    &.disabled {
      border-color: $grey-04;
      color: $grey-05;
    }
  }
  &.danger {
    background-color: $danger;
    color: $white;
    &:hover {
      background-color: $danger-light;
    }
    &:active,
    &.active {
      background-color: $danger-dark;
    }
    &.disabled {
      opacity: 0.2;
      background-color: $danger-dark;
    }
  }

  // Sizes
  &.large {
    padding: 13px 24px 32px 24px;
  }
  &.small {
    padding: 11px 16px 12px 16px;
    font-size: 12px;
  }
  &.mini {
    padding: 7px 12px 8px 12px;
    font-size: 12px;
  }
}

.content-btn {
  white-space: nowrap;
}
