.scenario-header {
  display: flex;
  align-items: center;
  padding: 0 24px 0 128px;
  margin-bottom: 16px;

  .name-title {
    width: 150px;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 1.11;
    letter-spacing: 1px;
  }
  .date-title {
    width: 96px;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 1.11;
    letter-spacing: 1px;
  }
  .progress-title {
    flex: 1;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 1.11;
    letter-spacing: 1px;
  }
  .actions-title {
    width: 217px;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 1.11;
    letter-spacing: 1px;
  }

  .completed {
    .Actions {
      width: 267px;
      text-transform: uppercase;
      font-size: 9px;
      line-height: 1.11;
      letter-spacing: 1px;
    }
  }
}
