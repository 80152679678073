.tooltip-wrapper{
    .tooltip-container{
        height: 2rem;
        width: auto;
        min-width: 4rem;
        color: white;
        margin-left: 0rem;
        text-align: center;
        padding-top: 0.5rem;
        font-size: 0.8rem;
    }

    .tooltip-arrow {
        margin-left: 0rem;
      }
}