@import '../../theme/colors';

.report-detail-screen {
  padding: 0 32px;
  flex: 1;
  color: $white;
  margin-left: 190px;
  height: 100% !important;
}

.reports-detail-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.phase-dropdown {
  width: 200px;
  margin-right: 20px;

  .report-input {
    margin-bottom: 0;
    height: 0;
  }
}

.scenario-dropdown {
  width: 200px;
}

.scenario-dropdown-skeleton {
  display: inline-block;
  width: 200px;
  height: 40px;
  background-color: $grey-04;
  background-image: linear-gradient(-45deg, $grey-01 20%, rgba($grey-04, 0.15) 50%, $grey-01 80%);
  background-size: 1000px 18px;
  animation: slide 1s infinite linear;
  position: absolute;
  z-index: 2;
}

@keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.report-detail-container {
  display: flex;
  flex: 0 0 100px;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
