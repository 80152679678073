.popover-heading {
  color: white;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  font-size: 1.2rem;
}

.subheading-container {
  margin-top: 2rem;
  margin-left: -0.4rem;
  height: 1.5rem;
  background-image: linear-gradient(to left, transparent, #1e222e);
}

.popover-subheading {
  font-size: 0.6rem;
  color: #aeb2c1;
  margin-left: 1.9rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
}

.item-container {
  overflow-y: auto;
  margin-left: -0.4rem;
}
