@import '../../theme/_colors.scss';
@import '../../theme/_variables.scss';
@import '../../theme/grid/_breakpoints.scss';
@import 'react-popper-tooltip/dist/styles.css';

.TheNavbar {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  height: 64px;
  width: 100vw;
  z-index: $zindex-navbar;
  background-color: $grey-01;
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
}

.Logo {
  display: inline-block;
  img {
    height: auto;
    width: 120px;
    position: relative;
    top: 2px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.6;
    }
  }
}

.client-logo {
  height: auto;
  width: 60px !important;
  margin-right: 10px;
}

.Wrapper {
  display: flex;
  align-items: center;
  font-family: $base-font-family;
  font-feature-settings: 'liga';
}

img {
  border-radius: 20px;
}

.tooltip-container {
  --tooltipBackground: #14161e !important;
  height: 35rem;
  width: 23rem;
  margin-left: -3rem;

  .tooltip-arrow {
    margin-left: 3rem;
  }
}

.beta-class {
  position: relative;
  top: -1rem;
  color: $white;
  border: 1px solid $white;
  border-radius: 2px;
  padding: 0px 3px 2px 3px;
  font-size: 0.5rem;
  cursor: pointer;
}

.test_button {
  margin: 19px -13px 0 0;
}
