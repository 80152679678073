.container1 {
    display: flex !important;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    // position: relative;
}


.filters {
    // position: absolute;
    top: 20px; /* Adjust the distance from the top */
    right: 20px; /* Adjust the distance from the right */
    padding: 10px 20px; /* Padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
}

.chart {
    // position: absolute;
    // margin-top: 60px; /* Space below the filter button */
    text-align: center;
    // padding: 20px; /* Padding */
    margin-top: -100px;
    border-radius: 10px; /* Rounded corners */
}

.stats-container {
    // background-color: #0f0f0f;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    // max-width: 600px;
    
}

.custom-card {
    background-color: #12141c;
    color: #fff;
    border-radius: 8px;
    box-shadow: none;
    // border: 0.5px solid #2d2d2d;
    font-size: x-small;
    margin-right: 10px;
}

.card-content {
    text-align: left;
}

.card-content h2 {
    margin: 0;
    font-size: 1.2em;
    color: #ccc;
}

.card-content h3 {
    margin: 0;
    font-size: 1.8em;
    font-weight: bold;
}

.cards {
    padding-right: 20px;
    display: flex;
}