@import '../../../../theme/colors';

.scenario-progress {
  height: 48px;
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: $grey-01;
  padding: 4px;
}

.done-progress {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 16px;
    height: 16px;
  }

  .WText {
    margin: 0 8px;
  }
}
