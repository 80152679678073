@import '../../theme/colors';

.phase-navbar {
  overflow: hidden;
}

.phase-navbar a {
  float: left;
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.subnav {
  float: left;
  overflow: hidden;
  border-radius: 20px;
}

.subnav .subnavbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #c8c7ce;
  padding: 25px 45px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.subnav-content a {
  float: left;
  color: white;
  text-decoration: none;
}

.subnav-content a:hover {
  /* background-color: black; */
  color: white;
  /* border-bottom: solid; */
  /* border-bottom-color: green; */
  /* border-width: thick; */
}

.phase-navbar a:hover,
.subnav:hover .subnavbtn {
  background-color: $green-hover;
}

.active {
  background-color: $green;
}

.subnav-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: #1e222e;
  width: 100%;
  z-index: 1;
}

.subnav:hover .subnav-content {
  display: block;
}

.faded {
  font-style: italic;
  color: grey !important;
}
