@import '../../theme/_colors';
@import '../../theme/_variables.scss';
@import '../../theme/grid/_breakpoints.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.WCard {
  border-radius: $border-radius * 2;
  box-shadow: 0 2px 5px 0 rgba($black, 0.2);
  background: $white;
  position: relative;

  &.brand {
    background-image: linear-gradient(274deg, $primary-light, $primary);
  }

  &.white {
    background: $white
  }

  &.dark {
    background: $grey-03
  }
}

.Header, .Body {
  padding: 30px;
}

.Overlay {
  border-radius: $border-radius * 2;
  background-color: $body-bg;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: .5;
}

.Spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
}