@import '../../theme/colors';

.report-screen {
  padding: 0 32px;
  flex: 1;
  color: $white;
  margin-left: 190px;
  height: 100% !important;
  .page-spinner {
    display: flex;
    justify-content: center;
  }
}

.empty-reports {
  width: 100%;
  min-height: 100px;
  height: auto;
  margin: 0 0.5 rem;
  padding: 23px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: 1px dashed #4a4e61;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: $grey-06;
}

.report-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}

.report-title {
  margin-right: 4px;
}

.formik-input-report {
  display: flex;
  justify-content: space-between;
}

.input-report {
  height: 15px;
}

.report-border-box {
  min-height: 150px;
  height: auto;
}

.phase-column {
  width: 22% !important;
  height: fit-content !important;
  overflow: hidden !important;
  
}

.report-title-custom {
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  border-top: thin green;
  border-right: thin green;
  border-bottom: thin solid green;
  border-left: thin green;
  font-weight: 100;
}

.dapi-input-reports {
  width: 70%;
}
