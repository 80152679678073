@import '../../theme/colors';

.action-bar {
  height: 80px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: $grey-03;
  padding: 0 32px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1000;

  .progress-bar-container {
    position: absolute;
    width: 100%;
    background-color: $grey-05;
    top: 1px;
    margin-left: -32px;
  }

  .progress-bar {
    background-color: $white;
    transform-origin: top left;
    transform: scale(0, 0);
  }

  .progress-bar-container,
  .progress-bar {
    height: 1px;
  }

  .scenario-content-text-statuses {
    min-width: 256px;
    .scenario-status-action {
      &.done {
        color: $success;
      }
      &.running {
        color: $primary;
      }
      &.expired {
        color: $warning;
      }
    }

    .scenario-phase-summary {
      width: 320px;
    }
  }
}

.phases-wrapper {
  width: 450px;
  height: 64px;
  background-color: $grey-01;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
  padding: 0 12px 0 16px;
}

.phase-action-bar {
  display: flex;
  align-items: center;

  &.void {
    .phase-item-action-bar,
    .sub-phase-line {
      border: solid 1px $primary;
      background-color: transparent;
    }
    & + .WText {
      color: $white;
    }
  }

  &.done {
    .phase-item-action-bar {
      border: solid 1px $success;
      background-color: $success;
    }
    .sub-phase-line {
      border: solid 1px rgba($success, 0.2);
      background-color: transparent;
    }
    & + .WText {
      color: $black;
    }
    &.expired {
      .phase-item-action-bar {
        border: solid 1px $warning;
        background-color: $warning;
      }
      .sub-phase-line {
        border: solid 1px rgba($warning, 0.2);
      }
    }
  }
}

.phase-item-action-bar {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  background-color: $grey-03;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.phase-running-action-bar {
  flex: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.sub-phase-line {
  width: 8px;
  height: 1px;
  border: solid 1px $grey-03;
  margin: 0 4px;
}
