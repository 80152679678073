@import '../../theme/_variables.scss';
@import '../../theme/mixins/global';
@import '../../theme/icons';

.WNotification {
	margin-bottom: 16px;
    border-radius: $border-radius;    
    color: $white;
    font-family: $font-family-henderson;
	font-size: $font-size;
	font-weight: $font-weight-light;
    line-height: 1.43;
	padding: 16px;
    z-index: 10000;
	min-width: 20rem;
	&.offset {
		margin-bottom: 80px;
	}
    .notification-title {
        font-family: $headings-font-family;
		font-weight: $headings-font-weight;
	}
    .notification-content {
        font-family: $headings-font-family;
		font-weight: $font-weight-light;
	}
	&.success, &.warning, &.error, &.information {
        background: $grey-02 !important;
		padding-left: 36px;
		&:before {
			position: absolute;
			transform: translate(-150%);
			font-family: $font-family-icomoon;
		}
	}
    &.success {
		&:before {
			content: "\E923";
			color: $success;
		}        
		border: 1px solid rgba($success, 0.4);
    }
    &.warning {
		&:before {
			content: "\E916";
			color: $warning;
		}
		border: 1px solid rgba($warning, 0.4);
    }
    &.information {
		&:before {
			content: "\E916";
			color: $warning;
		}
		border: 1px solid rgba($warning, 0.4);
    }
    &.error {
		&:before {
			content: "\E911";
			color: $danger;
		}
		border: 1px solid rgba($danger, 0.4);
    }
}