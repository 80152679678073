@import '../../../../theme/_colors';

.progress-scenario {
  // height: 32px;
  border-radius: 2px;
  // margin-left: -8px;
  z-index: 0;
  background-color: $primary;
  background-image: linear-gradient(
    -45deg,
    rgba($white, 0.15) 5%,
    $primary 5%,
    $primary 25%,
    rgba($white, 0.15) 25%,
    rgba($white, 0.15) 30%,
    $primary 30%,
    $primary 50%,
    rgba($white, 0.15) 50%,
    rgba($white, 0.15) 55%,
    $primary 55%,
    $primary 75%,
    rgba($white, 0.15) 75%,
    rgba($white, 0.15) 80%,
    $primary 80%
  );
  background-size: 1rem 1rem;
  animation: slide 2s infinite linear;
  transition: width 2s ease;
}

@keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 32px 0;
  }
}

.WText {
  z-index: 1;
}

.ScenarioProgressPhaseExpanded {
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  height: 32px;
  width: 100%;
  padding: 0 8px;
  border-radius: 2px;
  border: dashed 1px $primary;
  align-items: center;
  margin: 4px;
  &.error {
    border: solid 1px $danger;
  }
  &.valid {
    border: solid 1px $success;
  }
  &.running {
    border: solid 1px $primary;
  }
  &.progress {
    border: none;
    background-color: rgba($primary, 0.2);
  }
  &.done {
    border: solid 1px $success;
    background-color: $success;
  }
  &.stopped {
    border: solid 1px $warning;
  }
}
