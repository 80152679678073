@import '../../theme/_variables.scss';

.divider-wrapper {
  display: flex;
}

.hr-divider {
  display: block;
  width: 100%;
  height: 2px;
  background-color: $grey-04;
  border: 0;
}
