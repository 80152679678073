$font_path: '../assets/fonts/icomoon/';

@font-face {
  font-family: 'icomoon';
  src: url($font_path + 'icomoon.eot?r7mxue');
  src: url($font_path + 'icomoon.eot?r7mxue#iefix') format('embedded-opentype'),
    url($font_path + 'icomoon.woff2?r7mxue') format('woff2'), url($font_path + 'icomoon.ttf?r7mxue') format('truetype'),
    url($font_path + 'icomoon.woff?r7mxue') format('woff'),
    url($font_path + 'icomoon.svg?r7mxue#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important; //stylelint-disable-line
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-unarchive:before {
  content: '\e925';
}
.icon-archive:before {
  content: '\e90f';
}
.icon-view-all:before {
  content: '\e924';
}
.icon-view-less:before {
  content: '\e91d';
}
.icon-arrow-up:before {
  content: '\e90d';
}
.icon-arrow-right:before {
  content: '\e909';
}
.icon-arrow-down:before {
  content: '\e90e';
}
.icon-arrow-left:before {
  content: '\e90a';
}
.icon-notification:before {
  content: '\e921';
}
.icon-cancel:before {
  content: '\e91f';
}
.icon-error-fill:before {
  content: '\e911';
}
.icon-success:before {
  content: '\e919';
}
.icon-success-fill:before {
  content: '\e923';
}
.icon-warning-fill:before {
  content: '\e916';
}
.icon-stop:before {
  content: '\e920';
}
.icon-half-checked:before {
  content: '\e917';
}
.icon-checked:before {
  content: '\e918';
}
.icon-delete:before {
  content: '\e913';
}
.icon-close:before {
  content: '\e914';
}
.icon-close-large:before {
  content: '\e91a';
}
.icon-save:before {
  content: '\e915';
}
.icon-selected:before {
  content: '\e900';
}
.icon-unselected:before {
  content: '\e91b';
}
.icon-duplicate:before {
  content: '\e908';
}
.icon-search:before {
  content: '\e901';
}
.icon-scenarios:before {
  content: '\e902';
}
.icon-play:before {
  content: '\e903';
}
.icon-launch:before {
  content: '\e91c';
}
.icon-pause:before {
  content: '\e904';
}
.icon-new-normal:before {
  content: '\e905';
}
.icon-new-hover:before {
  content: '\e906';
}
.icon-help:before {
  content: '\e907';
}
.icon-edit:before {
  content: '\e90b';
}
.icon-add:before {
  content: '\e910';
}
.icon-view:before {
  content: '\e91e';
}
.icon-workspace:before {
  content: '\e922';
}
.icon-arrow-simple-up:before {
  content: '\e90c';
}
.icon-arrow-simple-down:before {
  content: '\e912';
}