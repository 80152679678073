.duplicate-modal {
  min-width: 90% !important;
}

.duplicate-fetch-spinner {
  height: 602px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.duplicate-fetch-error {
  margin: 64px;
}
