@import '../../theme/colors';
@import '../../theme/_variables.scss';

.WIcon {
  color: $black;
  vertical-align: middle;
  transition: all 0.3s ease;
  &.white {
    color: $white;
  }
  &.primary {
    color: $primary;
  }
  &.secondary {
    color: $primary-light;
  }
  &.black {
    color: $black;
  }
  &.dark {
    color: $black;
  }
  &.warning {
    color: $warning;
  }
  &.grayMedium {
    color: $black-o-40;
  }
  &.greenDark {
    color: $primary-dark;
  }
  &.danger {
    color: $danger;
  }
  &.grey06 {
    color: $grey-06;
  }
  &.clickable {
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  &.disabled {
    cursor: not-allowed;
    color: $grey-05;
    &:hover {
      color: $grey-05;
    }
  }
}
