@import '../../theme/colors';

.custom-ul {
  line-height: 30px;
  font-weight: 100;
}
.worksheet {
  margin-bottom: 5px;
  position: relative;
  &-skeleton {
    background-color: $grey-04;
    background-image: linear-gradient(-45deg, $grey-01 20%, rgba($grey-04, 0.15) 50%, $grey-01 80%);
    background-size: 1000px 18px;
    animation: slide 1s infinite linear;
  }
}
@keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 800px 0;
  }
}
