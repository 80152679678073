@import './_colors.scss';

// Body
$body-bg: $grey-01;
$body-color: $white;


// General
$border-radius: 4px;
$spacer: 16px;
$transition: 0.3s ease-in-out;

// Link
$link-color: $primary;
$link-hover-color: darken($primary, 10%);

// Fonts
$line-height-base: 1;

$font-family-icomoon: 'icomoon';

$font-family-henderson: 'Henderson', 'Helvetica Neue', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
'Segoe UI Symbol', 'Noto Color Emoji';
$base-font-family: $font-family-henderson;

$font-size: 14px;
$font-weight-thin: 300;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-base: $font-weight-normal;

$paragraph-margin-bottom: 0;

$headings-font-family: $font-weight-light;
$headings-font-weight: $font-weight-bold;
$headings-margin-bottom: 0;

// Forms
$form-color: $grey-06;
$form-placeholder-color: $grey-05;
$form-height: 28px;
$form-font-weight: $font-weight-base;
$form-font-size: 14px;
$form-border: 1px solid $grey-05;
$form-border-color-active: $primary;


// Z-index
$zindex-navbar: 1000;
