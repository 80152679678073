@import '../../theme/_colors';
@import '../../theme/_variables.scss';

$button-border-radius: 24px;

.sidebar {
  position: fixed;
  min-width: 190px;
  width: 190px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 32px;

  & a {
    text-decoration: none;
    // color: $white;
  }

  .navigation-item-scroll {
    height: 74%;
    overflow: auto;
  }
}

.links {
  width: 100%;
}

.sidebar-item {
  border-left: 2px solid transparent;
  color: $grey-06;
  display: flex;
  text-align: right;
  align-items: center;
  // width: 100%;
  margin: 2px 0;
  padding: 15px 30px;
  position: relative;
  transition: all 0.3s ease;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  .Icon {
    margin-right: 16px;
    color: $grey-06;
    transition: all 0.3s ease;
  }

  .Number {
    flex: 1;
  }

  &.active,
  &.router-link-active,
  &:hover,
  &:focus {
    background: $black;
    border-left-color: $primary;
    color: $white;

    .Icon {
      color: $white;
    }
  }

  &:active {
    background: rgba($black, 0.5);
    border-left-color: $primary-dark;
  }
}

.icon-space {
  margin-right: 1rem;
}

.add {
  display: inline-block;
  margin-right: 1px;
  height: 16px;
  width: 16px;
  position: relative;
  top: -1px;
  overflow: hidden;
  vertical-align: middle;

  &:before,
  &:after {
    content: '';
    background: $white;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: all 0.3s linear;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1px;
    width: 8px;
  }

  &:after {
    height: 8px;
    width: 1px;
  }
}

.action {
  margin-bottom: 40px;
}

.content {
  white-space: nowrap;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.WButton {
  background: rgba($primary, 0.2);
  border: none;
  border-radius: $button-border-radius;
  color: $white;
  cursor: pointer;
  outline: none;
  font-size: $font-size;
  font-family: $base-font-family;
  font-weight: $font-weight-normal;
  line-height: 1.43;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-left: 1rem;

  &.disabled {
    cursor: default;
  }

  &.block {
    width: 100%;
  }

  &.loading {
    padding: 0;
    opacity: 1;
    width: 50px;
    height: 50px;
  }

  // Types
  &.primary:not(.animated) {
    background-color: $primary;

    &:hover {
      background-color: $primary-light;
    }

    &:active,
    &.active {
      background-color: $primary-dark;
    }

    &.disabled {
      opacity: 0.2;
      background-color: $primary-dark;
    }
  }

  &.secondary {
    border: 1px solid $grey-05;
    background-color: $grey-01;

    &:hover {
      border-color: $primary-light;
    }

    &:active,
    &.active {
      border-color: $primary-dark;
    }

    &.disabled {
      border-color: $grey-04;
      color: $grey-05;
    }
  }

  &.danger {
    background-color: $danger;
    color: $white;

    &:hover {
      background-color: $danger-light;
    }

    &:active,
    &.active {
      background-color: $danger-dark;
    }

    &.disabled {
      opacity: 0.2;
      background-color: $danger-dark;
    }
  }

  // Sizes
  &.large {
    padding: 13px 24px 15px 24px;
  }

  &.small {
    padding: 11px 16px 12px 16px;
    font-size: 12px;
  }

  &.mini {
    padding: 7px 12px 8px 12px;
    font-size: 12px;
  }

  // Animated (special for sidebar)
  &.animated {
    background: rgba($primary, 0.2);
    border: none;
    border-radius: $button-border-radius;
    color: $white;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    font-family: $base-font-family;
    line-height: 1.43;
    position: relative;
    padding: 15px 20px;
    overflow: hidden;
    transition: all 0.3s ease;

    &:before {
      content: '';
      background: $primary;
      border-radius: $button-border-radius;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      z-index: -1;
    }

    &.disabled {
      cursor: default;
      opacity: 0.2;
      background-color: $primary;

      .add {
        &:before {
          width: 8px;
        }

        &:after {
          height: 8px;
        }
      }
    }

    &:active,
    &.active {
      &:not(.disabled) {
        &:before {
          background: $primary-dark;
          width: calc(100% - 8px);
          height: calc(100% - 8px);
        }
      }
    }

    &:hover:not(.disabled) {
      &:before {
        background: $primary-light;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
      }

      .add {
        &:before {
          width: 10px;
        }

        &:after {
          height: 10px;
        }
      }
    }
  }
}

.button-enter-active {
  transition: opacity 0.5s;
}

.button-leave-active {
  transition: opacity 0.1s;
}

.button-enter,
.button-leave-to {
  opacity: 0;
}

.d-none {
  display: none !important;
}