@import '../../../theme/colors';
@import '../../../theme/_variables.scss';
@import '../../../theme/grid/_breakpoints.scss';

.user-container {
  margin-top: 0;
}

.table {
  background-color: #13141c;
}

thead {
  color: #888ea5;
}

th {
  height: 2rem;
}

td {
  color: white;
  height: 4rem;
}

.table-responsive {
  min-height: 30rem;
  overflow-y: auto;

  th {
    position: sticky;
    top: 0; /* Don't */
  }

  td {
    padding: 0px 0.75rem;
  }

  td,
  th {
    vertical-align: middle;
  }

  // &.tableFixHead {
  //   width: 99%;
  //   table-layout: fixed;
  //   border-collapse: collapse;

  //   tbody {
  //     display: block;
  //     width: 99%;
  //     overflow: auto;
  //     height: 70vh;
  //   }
  //   thead tr {
  //     display: block;
  //   }

  //   th,
  //   td {
  //     width: 19.3%;
  //   }
  // }
}

.user-screen-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.active-green {
  border-color: #2cfb7d;
  background-color: #2cfb7d;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid transparent;
  margin-left: 1rem;
  margin-bottom: 0px;
}

.active-grey {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid transparent;
  margin-left: 1rem;
  margin-bottom: 0px;
  border-color: #888ea5;
  background-color: #888ea5;
}

.userform-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px;
}

.userform-details {
  margin-left: 1rem;
  margin-bottom: 2.5rem;
}
