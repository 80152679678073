.tooltip-span{
    .tooltip-container{
        height: 2rem;
        width: 5rem;
        color: white;
        margin: 0rem;
        text-align: center;      
        padding-top: 0.6rem;
        font-size: 0.6rem;
    }

    .tooltip-arrow {
        margin-left: 0rem;
        background-color: #14161e;
      }
}